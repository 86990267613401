import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { graphql, navigate } from "gatsby"
import CircularProgress from "@material-ui/core/CircularProgress"
import Parser from "html-react-parser"
import InputMask from "react-input-mask"
import Recaptcha from "react-recaptcha"
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from "@material-ui/core"

// import app components
import SidebarContent from "components/sidebarContent"
import Button from "components/button"
import getParameter from "utils/getParameter"
import { getUrlPath } from "utils/getUrlPath"

import { useQuery } from "@tanstack/react-query"

const QuoteRequestForm = (props) => {
  const {
    data: {
      wpPage: {
        title,
        uri,
        templateRetailQuoteRequest: { rqrFormId: formId }
      },
      wp: {
        themeSettings: {
          siteOptions: { defaultHeroImage }
        }
      },
      allWpRetailer: { retailers },
      allGfForm
    }
  } = props

  const [captchaVerified, setCaptchaVerified] = useState(false)
  const handleCaptchaVerify = (response) => {
    if (response) {
      setCaptchaVerified(true)
    }
  }

  const form = allGfForm.nodes.find((o) => +o.formId === +formId)

  const { apiURL } = form || {}

  const retailerId = getParameter("id")

  const projectId = getParameter("projectId")
  const projectQuery = useProjectQuery({ projectId })
  const project = projectQuery?.data?.data?.project
  const materialsString = project?.Project?.materials && materialsToString(project.Project.materials)

  const retailer = retailers && retailers.find((o) => o.databaseId.toString() === retailerId)

  const initialValues = {
    input_7: retailer?.databaseId, // retailer id - this is required
    input_33: ""
  }

  const [values, setValues] = useState(initialValues)

  const [submitForm, response] = useFetch(`${apiURL}/submissions`, {
    method: "POST",
    "Content-Type": "application/x-www-form-urlencoded"
  })

  const requiredFields =
    form?.formFields && form.formFields.map((field) => field.isRequired && field).filter((item) => item && item)

  const [formErrors, setFormErrors] = useState({})

  const containerRef = useRef(null)
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 120)

  const handleSubmit = () => {
    if (!captchaVerified) {
      alert("Please verify that you are not a robot.")
      return
    }
    // clear form errors
    setFormErrors({})

    // check required fields before submitting
    let newFormErrors = {}
    const missingFields = requiredFields.filter((el) => {
      const conditionalLogic = el?.conditionalLogic && JSON.parse(el.conditionalLogic)

      if (el.id === 45 && values[`input_44_7`] && !values[`input_45`]) {
        return true
      }

      if (!values[`input_${el.id}`] && !values[`input_${el.id}_1`] && !conditionalLogic) {
        return true
      } else {
        return false
      }
    })

    missingFields.length &&
      missingFields.forEach((element) => {
        const conditionalLogic = element?.conditionalLogic && JSON.parse(element.conditionalLogic)

        if (!conditionalLogic) {
          newFormErrors[`input_${element.id}`] = "This field is required"
        }
      })

    if (!!values[`input_44_7`] && !values[`input_45`]) {
      newFormErrors[`input_45`] = "This field is required"
    }

    if (!!values[`input_2`] && !values[`input_2`].includes("@")) {
      newFormErrors[`input_2`] = "Must be a valid email address"
    }

    if (missingFields.length || (values?.input_36 && values.input_36.includes("_"))) {
      // check that phone number doesn't include masked characters
      if (values?.input_36 && values.input_36.includes("_")) {
        newFormErrors.input_36 = "This field is required."
      }

      scrollToRef(containerRef)
      setFormErrors(newFormErrors)
      return
    }

    const formData = new FormData()

    Object.keys(values).map((key) => formData.append(key, values[key]))

    if (projectId && project?.Project?.materials) {
      const materialsString = materialsToString(project?.Project?.materials)

      formData.append("input_51", materialsString)
    }

    submitForm({ body: formData })
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })

    if (formErrors[e.target.name]) {
      let errors = formErrors
      delete errors[e.target.name]

      setFormErrors(errors)
    }
  }

  const getField = (id) => {
    return !!form?.formFields && form.formFields.find((field) => field.id === id)
  }

  const productTypesField = getField(44)
  const productChoices = productTypesField?.choices && JSON.parse(productTypesField.choices)

  const sidingProfileField = getField(16)
  const sidingProfileChoices = sidingProfileField?.choices && JSON.parse(sidingProfileField.choices)

  const sidingWidthField = getField(19)
  const sidingWidthChoices = sidingWidthField?.choices && JSON.parse(sidingWidthField.choices)

  const timeFrameField = getField(21)
  const timeFrameChoices = timeFrameField?.choices && JSON.parse(timeFrameField.choices)

  const hearAboutUsField = getField(48)
  const hearAboutUsChoices = hearAboutUsField?.choices && JSON.parse(hearAboutUsField.choices)

  if (!retailer && typeof window !== "undefined") {
    navigate("/find-a-retailer")
  }

  const renderContent = () => {
    if (!response.data && !response.error) {
      return (
        <Container ref={containerRef}>
          <Content>
            {retailer?.title && (
              <Typography variant="h3" gutterBottom>
                {Parser(retailer.title)}
              </Typography>
            )}
            <TabPanel>
              <StyledTextField
                halfWidth
                value={values.input_34 || ""}
                name="input_34"
                label="First Name"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_34}
                helperText={formErrors?.input_34}
              />

              <StyledTextField
                halfWidth
                value={values.input_35 || ""}
                name="input_35"
                label="Last Name"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_35}
                helperText={formErrors?.input_35}
              />

              <StyledTextField
                halfWidth
                value={values.input_6 || ""}
                name="input_6"
                label="Company"
                variant="filled"
                onChange={handleChange}
              />

              <StyledTextField
                halfWidth
                value={values.input_2 || ""}
                name="input_2"
                label="Email"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_2}
                helperText={formErrors?.input_2}
                type="email"
              />
              <InputMask mask="999-999-9999" value={values.input_36 || ""} onChange={handleChange}>
                {() => (
                  <StyledTextField
                    halfWidth
                    name="input_36"
                    label="Phone"
                    variant="filled"
                    required
                    error={formErrors?.input_36}
                    helperText={formErrors?.input_36}
                  />
                )}
              </InputMask>

              <StyledTextField
                fullWidth
                value={values.input_29 || ""}
                name="input_29"
                label="Address"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_29}
                helperText={formErrors?.input_29}
              />

              <StyledTextField
                halfWidth
                value={values.input_30 || ""}
                name="input_30"
                label="City"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_30}
                helperText={formErrors?.input_30}
              />

              <StyledTextField
                halfWidth
                value={values.input_31 || ""}
                name="input_31"
                label="State / Province"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_31}
                helperText={formErrors?.input_31}
              />

              <StyledTextField
                halfWidth
                value={values.input_32 || ""}
                name="input_32"
                label="ZIP / Postal Code"
                variant="filled"
                onChange={handleChange}
                required
                error={formErrors?.input_32}
                helperText={formErrors?.input_32}
              />

              <StyledFormControl halfWidth required error={formErrors?.input_33}>
                <InputLabel id="country-label" variant="filled">
                  Country
                </InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  name="input_33"
                  variant="filled"
                  value={values.input_33 || ""}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="Canada">Canada</MenuItem>
                  <MenuItem value="United States">United States</MenuItem>
                </Select>
                <FormHelperText>{formErrors?.input_33}</FormHelperText>
              </StyledFormControl>

              {project && (
                <div>
                  <Typography variant="h4">Project Materials</Typography>
                  <div style={{ whiteSpace: "pre-wrap", marginBottom: "24px" }}>{materialsString}</div>
                </div>
              )}

              {!projectId && (
                <>
                  <FormControl
                    component="fieldset"
                    css={css`
                      margin-bottom: 30px;
                    `}
                    fullWidth
                  >
                    <FormLabel component="legend">Product Type(s) Requested</FormLabel>

                    {productChoices &&
                      productChoices.map((choice, index) => {
                        return (
                          <React.Fragment key={index}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e, checked) => {
                                      checked
                                        ? setValues({
                                            ...values,
                                            [`input_44_${index + 1}`]: e.target.value
                                          })
                                        : setValues({ ...values, [`input_44_${index + 1}`]: "" })
                                    }}
                                    name={choice?.text}
                                    value={choice?.value}
                                  />
                                }
                                label={choice?.text}
                              />
                            </FormGroup>
                          </React.Fragment>
                        )
                      })}
                  </FormControl>
                  {values?.input_44_7 && (
                    <StyledTextField
                      error={formErrors?.input_45}
                      helperText={formErrors?.input_45}
                      fullWidth
                      value={values.input_45 || ""}
                      name="input_45"
                      required
                      label="Outdoor Living Project (specify)"
                      variant="filled"
                      onChange={handleChange}
                    />
                  )}

                  {values?.input_44_1 === "Siding" && sidingProfileChoices && (
                    <StyledFormControl halfWidth>
                      <InputLabel id="siding-profile-label" variant="filled">
                        Siding Profile
                      </InputLabel>
                      <Select
                        labelId="siding-profile-label"
                        id="siding-profile"
                        name="input_16"
                        variant="filled"
                        value={values.input_16 || ""}
                        onChange={handleChange}
                      >
                        {sidingProfileChoices.map((choice, index) => {
                          return (
                            <MenuItem key={index} value={choice?.value}>
                              {choice?.text}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </StyledFormControl>
                  )}

                  {values?.input_44_1 === "Siding" && sidingWidthChoices && (
                    <StyledFormControl halfWidth>
                      <InputLabel id="siding-profile-label" variant="filled">
                        Siding Width
                      </InputLabel>
                      <Select
                        labelId="siding-profile-label"
                        id="siding-profile"
                        name="input_19"
                        variant="filled"
                        value={values.input_19 || ""}
                        onChange={handleChange}
                        required
                      >
                        {sidingWidthChoices.map((choice, index) => {
                          return (
                            <MenuItem key={index} value={choice?.value}>
                              {choice?.text}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </StyledFormControl>
                  )}

                  <StyledFormControl component="fieldset" halfWidth>
                    <FormLabel component="legend">Grade</FormLabel>
                    <RadioGroup
                      aria-label="grade"
                      name="input_17"
                      value={values?.input_17 || ""}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="Clear" control={<Radio />} label="Clear" />
                      <FormControlLabel value="Knotty" control={<Radio />} label="Knotty" />
                    </RadioGroup>
                  </StyledFormControl>

                  <StyledFormControl component="fieldset" halfWidth>
                    <FormLabel component="legend">Kiln Dried or Green?</FormLabel>
                    <RadioGroup
                      aria-label="kiln-dried-or-green"
                      name="input_18"
                      value={values?.input_18 || ""}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="Kiln Dried" control={<Radio />} label="Kiln Dried" />
                      <FormControlLabel value="Green" control={<Radio />} label="Green" />
                    </RadioGroup>
                  </StyledFormControl>

                  <StyledTextField
                    halfWidth
                    value={values.input_20 || ""}
                    name="input_20"
                    label="Square footage of project"
                    variant="filled"
                    onChange={handleChange}
                  />
                </>
              )}

              <StyledFormControl halfWidth>
                <InputLabel id="time-frame-needed-label" variant="filled">
                  Time Frame Needed
                </InputLabel>
                <Select
                  labelId="time-frame-needed-label"
                  id="time-frame-needed"
                  name="input_21"
                  variant="filled"
                  value={values.input_21 || ""}
                  onChange={handleChange}
                >
                  {timeFrameChoices &&
                    timeFrameChoices.map((choice, index) => {
                      return (
                        <MenuItem key={index} value={choice?.value}>
                          {choice?.text}
                        </MenuItem>
                      )
                    })}
                </Select>
              </StyledFormControl>

              <TextField
                css={css`
                  margin-bottom: 30px;
                `}
                value={values.input_10 || ""}
                name="input_10"
                label="Additional information"
                variant="filled"
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />

              {hearAboutUsChoices && (
                <StyledFormControl halfWidth required error={formErrors?.input_48}>
                  <InputLabel id="siding-profile-label" variant="filled">
                    How did you hear about us?
                  </InputLabel>
                  <Select
                    labelId="siding-profile-label"
                    id="siding-profile"
                    name="input_48"
                    variant="filled"
                    value={values.input_48 || ""}
                    onChange={handleChange}
                  >
                    {hearAboutUsChoices.map((choice, index) => {
                      return (
                        <MenuItem key={index} value={choice?.value}>
                          {choice?.text}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>{formErrors?.input_48}</FormHelperText>
                </StyledFormControl>
              )}

              {values?.input_48 === "Other" && (
                <TextField
                  css={css`
                    margin-bottom: 30px;
                  `}
                  value={values.input_49 || ""}
                  name="input_49"
                  label="Other (please specify)"
                  variant="filled"
                  onChange={handleChange}
                  fullWidth
                />
              )}

              <FormControl
                component="fieldset"
                css={css`
                  margin-bottom: 30px;
                `}
                fullWidth
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e, checked) => {
                          checked
                            ? setValues({
                                ...values,
                                input_47_1: `newsletter`
                              })
                            : setValues({ ...values, input_47_1: "" })
                        }}
                        name="input_47_1"
                        value={values?.input_47_1 || ""}
                      />
                    }
                    label="I would like to subscribe to Real Cedar’s newsletter to receive the latest news."
                  />
                </FormGroup>
              </FormControl>

              <FormControl error={formErrors?.input_28}>
                {/* <FormLabel component="legend">Consent</FormLabel> */}
                <FormGroup>
                  <FormControlLabel
                    css={css`
                      align-items: flex-start;
                      .MuiFormControlLabel-label {
                        margin-top: 6px;
                      }
                    `}
                    control={
                      <Checkbox
                        onChange={(e, checked) => {
                          if (checked) {
                            setValues({ ...values, input_28_1: "1" })
                            if (formErrors[e.target.name]) {
                              let errors = formErrors
                              delete errors[e.target.name]

                              setFormErrors(errors)
                            }
                          } else {
                            setValues({ ...values, input_28_1: "" })
                            setFormErrors({ ...formErrors, input_28: "This field is required" })
                          }
                        }}
                        required
                        name="input_28"
                        checked={values?.input_28_1 === "1"}
                      />
                    }
                    label="By checking this box, I agree that a Western Red Cedar Lumber Association (WRCLA) retailer may contact me about the products I requested with the contact information I provided above. I also understand that I may receive marketing material from the WRCLA, and agree to the privacy policy outlined on this website."
                  />

                  <FormHelperText>{formErrors?.input_28}</FormHelperText>
                </FormGroup>
              </FormControl>
              <Recaptcha
                sitekey={process.env.GATSBY_RECAPTCHA}
                render="explicit"
                verifyCallback={handleCaptchaVerify}
                onloadCallback={() => console.log("reCAPTCHA loaded")}
              />

              <Button
                onClick={handleSubmit}
                disabled={response?.called}
                css={css`
                  margin-top: 30px;
                `}
              >
                {response.loading ? <CircularProgress size={20} /> : form?.button?.text}
              </Button>
            </TabPanel>
          </Content>
        </Container>
      )
    } else if (response.data) {
      return (
        <Message success>
          <Typography>{response?.data?.confirmation_message && Parser(response.data.confirmation_message)}</Typography>
        </Message>
      )
    } else {
      return (
        <Message>
          <Typography component="div">Something went wrong. Please try again.</Typography>
        </Message>
      )
    }
  }

  return (
    <SidebarContent
      image={defaultHeroImage}
      title={title}
      slug={getUrlPath(uri)}
      hideSidebar={true}
      hideSidebarOnMobile
      hideHeader={false}
      edgesSize={"default"}
    >
      {renderContent()}
    </SidebarContent>
  )
}

const Message = styled.div`
  width: 100%;
  border: 1px solid ${(props) => (props.success ? "#2dad38" : "#e24141")};
  padding: 10px 14px;
  margin-top: 20px;
`
const Container = styled.div`
  max-width: 800px;
`

const Content = styled.div`
  margin: 30px 0;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 30px;
  width: 100%;
  @media (min-width: 700px) {
    ${({ halfWidth }) =>
      halfWidth &&
      css`
        width: calc(50% - 15px);
      `}
  }
`

const StyledFormControl = styled(FormControl)`
  margin-bottom: 30px;
  width: 100%;
  @media (min-width: 700px) {
    ${({ halfWidth }) =>
      halfWidth &&
      css`
        width: calc(50% - 15px);
      `}
  }
`

const TabPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

const useFetch = (endpoint, options = {}) => {
  const [state, setState] = useState({
    error: null,
    data: null,
    loading: false,
    called: false
  })

  const callback = async (args) => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    // const params = args && createQueryString(args)

    await fetch(endpoint, {
      method: "get",
      ...options,
      ...args
    })
      .then(async (response) => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })
      })
      .catch((error) => {
        setState({ ...state, error, loading: false, called: true })
      })
  }

  return [callback, state]
}

export const CollectionQuery = graphql`
  query RetailQuoteRequest($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      templateRetailQuoteRequest {
        rqrFormId
      }
    }
    allGfForm {
      nodes {
        formId
        title
        slug
        apiURL
        labelPlacement
        descriptionPlacement
        formFields {
          id
          label
          labelPlacement
          isRequired
          checkboxLabel
          conditionalLogic
          description
          descriptionPlacement
          type
          choices
          content
          errorMessage
          inputMaskValue
          visibility
          cssClass
          placeholder
          size
          defaultValue
          maxLength
        }
        button {
          text
        }
        confirmations {
          id
          name
          type
          message
        }
      }
    }

    allWpRetailer(filter: { Retailer: { email: { ne: "" }, quoteButton: { eq: true } } }) {
      retailers: nodes {
        databaseId
        title
        Retailer {
          premiumRetailer {
            ... on WpMember {
              Members {
                excludeFromOrganicSearchResults
                smallMemberLogo {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeSettings {
        siteOptions {
          defaultHeroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default QuoteRequestForm

const useProjectQuery = ({ projectId }) => {
  const projectQuery = useQuery({
    queryFn: async () => {
      const request = await fetch(`${process.env.GATSBY_WP}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query: /* GraphQL */ `
            query ProjectQuery($id: ID!) {
              project(id: $id, idType: DATABASE_ID) {
                id
                title
                Project {
                  materials {
                    group {
                      label
                      items {
                        description
                        fieldGroupName
                        length
                        material
                        nominalSizes
                        partNumber
                        quantity
                        thickness
                        width
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            id: projectId
          }
        })
      })

      const body = await request.json()
      return body
    },

    enabled: !!projectId
  })

  return projectQuery
}

function materialsToString(materials) {
  let result = ""

  materials.forEach((materialGroup) => {
    materialGroup.group.items.forEach((item) => {
      result += `${item.material}, Nominal Sizes: ${item.nominalSizes}, Thickness: ${item.thickness}, Width: ${item.width}, Quantity: ${item.quantity}\n`
    })
  })

  return result
}
